<template>
  <div>
    <v-container grid-list-xl fluid>
      <header-title title="Generar y listar butaca"></header-title>
      <div v-if="convocatoria.id > 0">
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-card-text>
                <p class="subtitle-1 font-weight-bold">
                  {{ convocatoria.cite }}
                </p>
                <p>
                  Lista de postulantes, sedes y butaca por EFM/UA modalidad
                  <b>A</b>.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mb-1">
          <v-col cols="12">
            <v-card>
              <v-card-text>
                <v-form ref="formp" @submit.prevent="searchPostulantes()">
                  <v-row class="mt-1">
                    <v-col cols="12" sm="3">
                      <v-select
                        label="ESFM/UA"
                        v-model="options.institucion_id"
                        :items="instituciones"
                        item-text="nombre"
                        item-value="id"
                        :rules="[(v) => !!v || 'Seleccione una opción']"
                        @change="changeInstitucion()"
                        hide-details
                        filled
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-text-field
                        v-model="options.search"
                        label="CI, nombre, apellidos"
                        @input="changeSearch"
                        hint="1234567 o 1234567-1G"
                        hide-details
                        clearable
                        filled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-btn type="submit" large color="primary">
                        <v-icon>mdi-magnify</v-icon>
                        Buscar
                      </v-btn>
                      <v-btn
                        class="ml-1"
                        type="button"
                        large
                        color="error"
                        :loading="load_report"
                        :disabled="postulantes.length == 0"
                        @click="reportButaca"
                      >
                        <v-icon>mdi-file-download</v-icon>
                        PDF
                      </v-btn>
                      <!-- <v-btn
                        class="ml-1"
                        type="button"
                        large
                        color="success"
                        @click="confirmDialog = true"
                      >
                        Butaca
                      </v-btn> -->
                    </v-col>
                  </v-row>
                </v-form>
                <v-data-table
                  :loading="loading"
                  :headers="headers"
                  :items="postulantes"
                  :page.sync="options.page"
                  :items-per-page="options.itemsPerPage"
                  hide-default-footer
                  class="elevation-1"
                >
                  <template v-slot:[`item.num`]="{ item }">
                    <td>
                      {{
                        (options.page - 1) * options.itemsPerPage +
                        (postulantes.indexOf(item) + 1)
                      }}
                    </td>
                  </template>
                  <template v-slot:[`item.nombre`]="{ item }">
                    <td>
                      {{ item.a_postulante.a_persona.paterno }} {{ item.a_postulante.a_persona.materno }}
                      {{ item.a_postulante.a_persona.nombres }}
                    </td>
                  </template>
                  <template v-slot:[`item.a_persona.fecha_nacimiento`]="{ item }">
                    <td>
                      {{
                        item.a_persona.fecha_nacimiento | formatdate
                      }}
                    </td>
                  </template>
                </v-data-table>
                <div class="v-data-footer">
                  <v-row>
                    <v-col cols="12" sm="4">
                      <div class="v-data-footer__select">
                        Filas por página:
                        <v-select
                          :items="[10, 20, 30, 50, 100]"
                          v-model="options.itemsPerPage"
                          @change="changePerPage()"
                          hide-details
                          class="my-0"
                        ></v-select>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="8" class="text-center">
                      <v-pagination
                        v-model="options.page"
                        :length="options.pageCount"
                        :total-visible="5"
                        @input="changePage"
                      ></v-pagination>
                    </v-col>
                  </v-row>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <notify-card
          :title="notify_title"
          :load="notify_load"
          process="el proceso de admisión"
        ></notify-card>
      </div>
    </v-container>
    <v-dialog v-model="confirmDialog" width="300">
      <v-card>
        <v-card-title class="headline grey lighten-3" primary-title>
          <span class="headline">Alerta</span>
        </v-card-title>
        <v-card-text class="text-center">
          <p>¿Está seguro(a) de generar los <b>números de butaca</b>?</p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            type="submit"
            color="success"
            :loading="load_generate"
            @click="generateButaca"
            text
            >Aceptar</v-btn
          >
          <v-btn color="red" text @click="confirmDialog = false"
            >Cancelar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import HeaderTitle from "@/components/HeaderTitle";
import NotifyCard from "@/components/NotifyCard";
import Service from "../utils/general";
import PDFService from "../utils/pdf";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { mapActions } from "vuex";
import axios from "axios";

export default {
  name: "admision-lista-butaca",
  components: {
    HeaderTitle,
    NotifyCard,
  },
  data: () => ({
    notify_title: "Cargando",
    notify_load: true,
    loading: false,
    load_report: false,
    load_generate: false,
    convocatoria: {
      id: null,
      cite: "",
      descripcion: "",
      gestion: "",
    },
    headers: [
      {
        text: "#",
        align: "start",
        sortable: false,
        value: "num",
      },
      {
        text: "Apellidos y nombres",
        value: "a_postulante.a_persona.nombre_completo",
      },
      { text: "Cédula identidad", value: "a_postulante.a_persona.cedula_dni" },
      { text: "Fecha nacimiento", value: "a_postulante.a_persona.fecha_nacimiento" },
      { text: "Especialidad", value: "especialidad.descripcion" },
      { text: "Butaca", value: "butaca" },
      { text: "Recinto", value: "sede.nombre" },
    ],
    options: {
      search: "",
      convocatoria_id: "",
      institucion_id: "",
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
    },
    instituciones: [],
    postulantes: [],
    confirmDialog: false,
    user: null,
  }),
  created() {
    this.user = Service.getUser();
    if (this.user == null) {
      this.$router.replace({ name: "root" });
    }
  },
  mounted() {
    if (this.user &&  (this.user.rid === 1||this.user.rid === 13)) {
      this.getParametricas();
    } else {
      this.$router.replace({ name: "root" });
    }
  },
  methods: {
    ...mapActions(["toast"]),
    /* getInstitutionParam() {
      this.notify_load = true;
      Service.svcInsitutionParam()
        .then((response) => {
          if (response.status === 200) {
            this.convocatoria = response.data.convocatorias[0];
            this.instituciones = response.data.instituciones;
          }
          this.notify_title = "Notificación";
          this.notify_load = false;
        })
        .catch(() => {
          this.notify_load = false;
          console.error("Parametros no encontrado");
        });
    }, */
    getParametricas() {
      this.notify_load = true;
      axios
        .get(
          Service.getUrlAPI() + "admision/convocatoria/institucion",
          Service.getHeader()
        )
        .then((response) => {
          this.convocatoria = response.data.convocatorias
            ? response.data.convocatorias[0]
            : { id: null, cite: "", descripcion: "", gestion: "" };
          this.instituciones = response.data.instituciones;
          this.options.convocatoria_id = this.convocatoria.id;
          this.notify_title = "Notificación";
          this.notify_load = false;
        })
        .catch(() => {
          this.notify_load = false;
          console.error("Parametros no encontrado");
        });
    },

    getPostulantes() {
      this.loading = true;
      axios
        .post(
          Service.getUrlAPI() + "admision/listar/butaca",
          this.options,
          Service.getHeader()
        )
        .then((response) => {
          this.loading = false;
          this.postulantes = response.data.rows;
          if (response.data.count > 0) {
            this.options.pageCount =
              Math.floor(response.data.count / this.options.itemsPerPage) + 1;
          }
        })
        .catch((error) => {
          this.loading = false;
          console.error("Error al cargar registros", error);
        });
    },

    searchPostulantes() {
      if (this.$refs.formp.validate()) {
        this.getPostulantes();
      } else {
        this.toast({ color: "info", text: "Seleccione ESFM/UA" });
      }
    },

    changeSearch() {
      if (this.options.search && this.options.search.length > 2) {
        this.getPostulantes();
      }
    },

    changePerPage() {
      this.options.page = 1;
      this.getPostulantes();
    },

    changePage() {
      this.getPostulantes();
    },

    changeInstitucion() {
      this.postulantes = [];
      this.options.page = 1;
      this.options.pageCount = 0;
      this.options.itemsPerPage = 10;
      this.getPostulantes();
    },

    generateButaca() {
      this.load_generate = true;
      axios
        .post(
          Service.getUrlAPI() + "admision/generar/butaca",
          this.options,
          Service.getHeader()
        )
        .then((response) => {
          this.load_generate = false;
          if (response.status === 200) {
            this.confirmDialog = false;
            this.toast({ color: "success", text: response.data.message });
          } else {
            this.toast({ color: "error", text: response.data.message });
          }
        })
        .catch((error) => {
          this.load_generate = false;
          console.error("Error al cargar registros", error);
        });
    },

    reportButaca() {
      this.load_report = true;
      axios
        .post(
          Service.getUrlAPI() + "admision/reporte/butaca",
          this.options,
          Service.getHeader()
        )
        .then((response) => {
          let bodyPdf = [];
          if (response.status == 200) {
            const rcount = response.data.registro.length - 1;
            response.data.registro.forEach((item, index) => {
              let pbreak = index < rcount ? "after" : null;
              bodyPdf.push(
                PDFService.bodyButacaPDF(this.convocatoria, item, pbreak)
              );
            });
            var docDefinition = {
              watermark: {
                text: "Prueba Escrita",
                opacity: 0.06,
                angle: 315,
              },
              pageSize: "A5",
              pageMargins: [40, 70, 40, 30],
              content: bodyPdf,
            };
            pdfMake.createPdf(docDefinition).download("PostulantesButaca.pdf");
          } else {
            this.toast({ color: "info", text: "No hay registro" });
          }
          this.load_report = false;
        })
        .catch((err) => {
          this.load_report = false;
          console.error(err);
        });
    },
  },
};
</script>
